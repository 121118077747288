<template>
  <div class="game-container">
    <LearnReview
      :imgList="imgList"
      :wordsList="wordsList"
      :sentenceList="sentenceList"
      @deleteWords="handleDeleteWords"
    />
  </div>
</template>

<script>
import LearnReview from "@/components/Course/GamePage/LearnReviewGame";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          imgUrl: require("@/assets/img/11-Syllabus/lesson-04-hengzhe-yellow.svg"),
          type: 3,
          show: true,
        },
        {
          id: 2,
          imgUrl: require("@/assets/img/11-Syllabus/lesson-04-san-white.svg"),
          type: 3,
          show: true,
        },
        {
          id: 3,
          imgUrl: require("@/assets/img/11-Syllabus/lesson-04-wu-white.svg"),
          type: 3,
          show: true,
        },
      ],
      wordsList: [
        {
          id: 1,
          name: "yī",
          namePin: "一",
          type: 1,
          show: true,
        },
        // yī èr sān sì wǔ.
        {
          id: 2,
          name: "èr",
          namePin: "二",
          type: 1,
          show: true,
        },
        {
          id: 3,
          name: "sān",
          namePin: "三",
          type: 1,
          show: true,
        },
        {
          id: 4,
          name: "sì",
          namePin: "四",
          type: 1,
          show: true,
        },
        {
          id: 5,
          name: "wǔ",
          namePin: "五",
          type: 1,
          show: true,
        },
        {
          id: 6,
          name: "lǎoshī",
          namePin: "老师",
          type: 1,
          show: true,
        },
      ],
      sentenceList: [
        {
          id: 1,
          name: "Lǎoshī hǎo!",
          namePin: "老师好！",
          type: 2,
          show: true,
        },
      ],
    };
  },
  components: {
    LearnReview,
  },
  methods: {
    handleDeleteWords({ type, id }) {
      if (type === 1) {
        this.wordsList[id - 1].show = false;
      } else if (type === 2) {
        this.sentenceList[id - 1].show = false;
      } else {
        this.imgList[id - 1].show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
