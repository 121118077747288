<template>
  <div class="game-container">
    <WriteHanziPage
      :rightImgList="rightImgList"
      :strokeStepImgList="strokeStepImgList"
      :hanziInfo="hanziInfo"
    />
  </div>
</template>

<script>
import WriteHanziPage from "@/components/Course/CoursePage/WriteHanziPage";
export default {
  data() {
    return {
      hanziPinyin: "sān",
      hanziInfo: {
        hanziBgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-04/hanzi-san-grey-border.svg"),
        strokeRemindImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-04/hanzi-san-stroke-order.svg"),
        pinyin: "sān",
      },
      imgIndexList: [1, 2, 3, 5],
    };
  },
  computed: {
    rightImgList() {
      const imgArr = [];
      for (let i = 0; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-04/carrot-${this.imgIndexList[i]}.svg`)
        );
      }
      return imgArr;
    },
    strokeStepImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-04/lesson-04-san-${i}.gif`)
        );
      }
      return imgArr;
    },
  },
  components: {
    WriteHanziPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











